import { Vue, Component } from "vue-property-decorator";
import _, { DebouncedFunc } from "lodash";

@Component
export default class FuncMixins extends Vue {
  public debouncer!: DebouncedFunc<any>;

  public created() {
    this.debouncer = _.debounce((callback: any) => callback(), 500);
  }
}
