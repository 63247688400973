














import { Component, Prop } from "vue-property-decorator";
import { BModal } from "bootstrap-vue/src/components/modal";
import ModalMixin from "@/mixins/ModalMixin";
import { mixins } from "vue-class-component";
import FscModal from "@/components/Modal/FscModal.vue";
import i18n from "@/i18n";

@Component({
  components: { FscModal, BModal },
})
export default class ArchiveModal extends mixins(ModalMixin) {
  public name = "ArchiveModal";

  @Prop({ type: String, required: true })
  protected modalId!: string;

  @Prop()
  public title!: string;

  @Prop()
  public question!: string;

  @Prop()
  public unarchiveQuestion: any;

  @Prop()
  public archiveAction!: any;

  @Prop()
  public resource!: string;

  @Prop()
  public itemId!: number;

  @Prop({ type: Boolean, default: () => false })
  public isArchived: any;

  @Prop({ type: String, default: () => i18n.t("general.yes") })
  public archiveButtonLabel!: any;

  @Prop({ type: String, default: () => i18n.t("general.no") })
  public abortButtonLabel!: any;

  private archive(): void {
    if (this.isArchived) {
      this.archiveAction({
        resource: this.resource,
        params: { archived: false, id: this.itemId },
      });
    } else {
      this.archiveAction({
        resource: this.resource,
        params: { archived: true, id: this.itemId },
      });
    }
  }

  private getQuestion(): string {
    if (this.isArchived) {
      return this.unarchiveQuestion;
    } else {
      return this.question;
    }
  }

  public show(): any {
    this.showModal(this.modalId);
    this.$emit("on-show");
  }

  private close(): any {
    this.hideModal(this.modalId);
    this.$emit("on-close");
  }
}
