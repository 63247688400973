









import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class AbortButton extends Vue {
  public name = "AbortButton";

  @Prop({ default: () => "Abbrechen", type: String })
  public label!: string;

  @Prop({ default: () => "", type: String })
  public labelClass!: string;
}
