




import { Component, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import ButtonMixin from "@/mixins/ButtonMixin";
import ActionButton from "@/components/Button/ActionButton.vue";
@Component({
  components: { ActionButton },
})
export default class ArchiveButton extends mixins(ButtonMixin) {
  public name = "ArchiveButton";

  @Prop({ type: Boolean, default: () => false })
  public archived!: any;

  public iconStyle = "fat";

  @Watch("archived", { deep: true, immediate: true })
  public onArchiveChange(archived: any) {
    if (archived) {
      this.iconStyle = "fas";
    } else {
      this.iconStyle = "fat";
    }
  }
}
