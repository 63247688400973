









import { Component, Prop, Vue } from "vue-property-decorator";
import { v4 as uuidv4 } from "uuid";

@Component
export default class MagicStickButton extends Vue {
  public name = "MagicStickButton";

  @Prop({ default: () => uuidv4() })
  public id!: string;

  @Prop()
  public tooltipLabel: any;
}
